import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ac_cookie_modal_block = _resolveComponent("ac-cookie-modal-block")!
  const _component_ac_cookie_modal_content = _resolveComponent("ac-cookie-modal-content")!
  const _component_settings_content = _resolveComponent("settings-content")!

  return (_openBlock(), _createBlock(_component_settings_content, { class: "page-cookie" }, {
    default: _withCtx(() => [
      _createVNode(_component_ac_cookie_modal_content, {
        "is-modal": false,
        onAccept: _cache[1] || (_cache[1] = ($event: any) => (_ctx.accept('accept'))),
        onDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.accept('dismiss'))),
        "show-title": _ctx.showTitle,
        "show-close-btn": false,
        class: "page-cookie_content",
        "disabled-accept-btn": _ctx.disabledAcceptBtn
      }, {
        blocks: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mappedCookies, (cookie, key) => {
            return (_openBlock(), _createBlock(_component_ac_cookie_modal_block, {
              key: `cookiePolicy-${key}`,
              cookie: cookie,
              onToggle: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleCookie($event))),
              class: "page-cookie_block"
            }, null, 8, ["cookie"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["show-title", "disabled-accept-btn"])
    ]),
    _: 1
  }))
}