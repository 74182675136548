
import { defineComponent, computed, ref, watch } from 'vue'

import SettingsContent from '@web/components.v2/Settings/Content.vue'
import AcCookieModalContent from '@ui-kit/components/AcCookie/CookieModalContent.vue'
import AcCookieModalBlock from '@ui-kit/components/AcCookie/CookieModalBlock.vue'

import { useStore } from '@web/store'
import Cookies from 'js-cookie'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'
import Swal from 'sweetalert2'
import i18n from '@web/plugins/i18n'

export default defineComponent({
  name: 'CCookiePolicy',
  components: {
    AcCookieModalBlock,
    AcCookieModalContent,
    SettingsContent
  },
  props: {
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const store = useStore()
    const KEY = 'is_cookies_policy_set'
    const localCookiePolicy = ref({
      essential: true,
      analytics: false,
      marketing: false
    })
    const disabledAcceptBtn = ref(false)

    const cookies = computed(() => store.getters['cookie/cookies'])
    const cookiePolicy = computed(() => store.getters['cookie/policy'])
    const mappedCookies = computed(() => {
      return Object.keys(cookiePolicy.value)
        .map((type) => {
          const _cookies = Object.values(cookies.value).find((c) => c.key === type)
          return _cookies
            ? {
                ..._cookies,
                enabled: cookiePolicy.value[type]
              }
            : undefined
        })
        .filter(c => c)
    })

    async function accept (type: 'dismiss' | 'accept' | 'acceptAll') {
      disabledAcceptBtn.value = true
      if (type === 'dismiss') {
        await store.dispatch('cookie/updatePolicy', {
          essential: true,
          analytics: false,
          marketing: false
        })
      } else if (type === 'acceptAll') {
        await store.dispatch('cookie/updatePolicy', {
          essential: true,
          analytics: true,
          marketing: true
        })
      } else {
        await store.dispatch('cookie/updatePolicy', localCookiePolicy.value)
      }
      Cookies.set(KEY, 'true', { sameSite: 'Strict', expires: 365 })
      Swal.fire({
        icon: 'success',
        title: `${i18n.global.t('successfully')}!`,
        confirmButtonText: i18n.global.t('close')
      })
      disabledAcceptBtn.value = false
    }

    function toggleCookie (type: 'essential' | 'analytics' | 'marketing') {
      if (type !== 'essential') {
        localCookiePolicy.value[type] = !localCookiePolicy.value[type]
      }
    }

    async function init () {
      try {
        await store.dispatch('cookie/fetchCookies')
        await store.dispatch('cookie/fetchPolicy')
      } catch (e) {
        sentry.captureException(e)
        Logger.error(e)
      }
    }

    init()

    watch(cookiePolicy, () => {
      localCookiePolicy.value = cookiePolicy.value
    }, {
      immediate: true
    })

    return {
      mappedCookies,
      disabledAcceptBtn,
      accept,
      toggleCookie
    }
  }
})
