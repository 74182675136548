
import { computed, defineComponent } from 'vue'
import { MqResponsive } from 'vue3-mq'

import { useStore } from '@web/store'
import i18n from '@web/plugins/i18n'
import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import { useRouter } from 'vue-router'
import { useMeta } from '@web/common/vueMeta'
import CCookiePolicy from '@web/components.v2/Settings/Cookie/index.vue'

export default defineComponent({
  name: 'CookiePolicy',
  components: {
    CCookiePolicy,
    MqResponsive,
    AsHeaderMobile,
    AsHeaderMain,
    AsMenu,
    AsFooter,
    AsSpacer,
    AsBreadcrumb
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const title = i18n.global.t('linkSocialNetworks')
    useMeta({
      title
    })
    const menu = computed(() => store.getters['menu/general'])

    function goBack () {
      router.push({ name: 'userSettings' })
    }

    return {
      title,
      menu,

      goBack
    }
  }
})
